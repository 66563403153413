import React, { useEffect, useRef, useState } from "react"
import {
  frycooks,
  bg,
  fryCookHeader,
  video,
  fryCookText,
  mintBtn,
  mintAdopt,
  arrow,
  errorText,
  errorBtn,
  btnDisabled,
  incrementBtn,
  arrowContainer,
  count,
  mint,
  remaining,
} from "../styles/layout/frycooks/index.module.scss"
import vid from "../images/dumpster.mp4"
import ArrowRight from "../components/base/svg/arrowRight"
import ArrowLeft from "../components/base/svg/arrowLeft"
import ProgressBar from "../components/base/svg/progressBar"
import {
  ingMarketStatus,
  closedRed,
} from "../styles/layout/ingredients/index.module.scss"
import {
  statusSpace,
  mintClosed,
} from "../styles/layout/cookredeem/index.module.scss"
import Spinner from "../components/base/svg/spinner"
import {
  faqContainer,
  faqSection,
  faqHeader,
  faqText,
  faqWrapper,
  faqLink,
} from "../styles/layout/faq/index.module.scss"
import { Link } from "gatsby"
import { INGREDIENTS_ABI, INGREDIENTS_ADDRESS } from "../constants/contractAddr"

declare global {
  interface Window {
    Web3: any
    ethereum: any
    web3: any
  }
}

const totalInCollection = 2018

const MARKET_STATUS = {
  OPEN: "open",
  CLOSED: "closed",
  LOADING: "loading",
  ERROR: "error loading check wallet settings and refresh",
}

const FryCooks = () => {
  const WalletErrors = {
    NO_WEB3: "Error loading Web3",
    NETWORK_ERROR: "Network error try again",
    METAMASK_CONNECT_ERROR: "Error connecting to Metamask",
    NO_WALLET_AVAILABLE: "No wallet available",
    UNKNOWN_ERROR: "Unknown error refresh and try again",
    PROVIDER_NO_CHAIN_CONNECT: "Web3 provider not connected to chain",
    ADOPT_ERROR:
      "Mint error verify wallet balance & Metamask version is at least 10.1 if using a hardware wallet",
    NO_MAINNET: "Not connected to Mainnet",
    ERROR_FETCHING_MARKET: "Error fetching market status, please refresh",
  }

  const ADOPT_STATE = {
    ADOPTING: "adopting",
    ADOPTED: "adopted",
  }

  interface walletState {
    address: string | null
    network: string | null
    errorMessage: string | null
    jabbas: any
    web3Loaded: boolean
    totalSupply: number | null
    readyToPurchase: boolean
    adoptState: string | null
    marketOpen: string
  }

  const initialWalletState = {
    address: null,
    network: null,
    errorMessage: null,
    jabbas: [],
    web3Loaded: false,
    totalSupply: null,
    readyToPurchase: false,
    adoptState: null,
    marketOpen: MARKET_STATUS.LOADING,
  }

  const [mintCount, setMintCount] = useState(1)

  const [walletState, setWalletState] =
    useState<walletState>(initialWalletState)

  const decrementMintCount = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1)
    } else {
      setMintCount(1)
    }
  }

  const incrementMintCount = () => {
    if (mintCount < 19) {
      setMintCount(mintCount + 1)
    } else {
      setMintCount(20)
    }
  }

  const videoRef = useRef<HTMLVideoElement>(null)

  const requireWeb3 = () => {
    return new Promise<void>((resolve, reject) => {
      if ("web3" in window && "ethereum" in window) {
        if (window.ethereum.isConnected()) {
          resolve()
        } else {
          reject(WalletErrors.PROVIDER_NO_CHAIN_CONNECT)
        }
      } else {
        reject(WalletErrors.NO_WEB3)
      }
    })
  }

  const getNetwork = () => {
    return new Promise<string>((resolve, reject) => {
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((response: string) => {
          let networkString = ""

          switch (response) {
            case "0x1": {
              networkString = "Mainnet"
              break
            }
            case "0x3": {
              networkString = "Ropsten Test Network"
              break
            }
            case "0x4": {
              networkString = "Rinkeby Test Network"
              break
            }
            case "0x5": {
              networkString = "Goerli Test Network"
              break
            }
            case "0x2a": {
              networkString = "Kovan Test Network"
              break
            }
            default: {
              networkString = ""
              break
            }
          }
          setWalletState(prevState => ({
            ...prevState,
            network: networkString,
          }))

          if (networkString != "Mainnet") {
            reject(WalletErrors.NO_MAINNET)
          }

          resolve(networkString)
        })
        .catch(() => {
          reject(WalletErrors.NETWORK_ERROR)
        })
    })
  }

  const connectMetaMask = (network: string) => {
    return new Promise<string>((resolve, reject) => {
      if (window.ethereum) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(() => {
            if (window.ethereum.selectedAddress) {
              resolve(network)
            } else {
              reject(WalletErrors.NO_WALLET_AVAILABLE)
            }
          })
          .catch(() => {
            reject(WalletErrors.METAMASK_CONNECT_ERROR)
          })
      } else {
        reject(WalletErrors.NO_WALLET_AVAILABLE)
      }
    })
  }

  const updateWalletInfo = (network: string) => {
    return new Promise<string>((resolve, reject) => {
      if (window.ethereum.selectedAddress) {
        setWalletState(prevState => ({
          ...prevState,
          address: window.ethereum.selectedAddress,
          readyToPurchase: true,
        }))
      } else {
        reject(WalletErrors.NO_WALLET_AVAILABLE)
      }
      resolve(network)
    })
  }

  const play = () => {
    videoRef.current?.play()
  }

  const fetchMarketStatus = () => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const ingContract = new window.web3.eth.Contract(
          INGREDIENTS_ABI,
          INGREDIENTS_ADDRESS
        )

        const isMarketOpen = await ingContract.methods.getMarket().call()

        const marketOpen = isMarketOpen
          ? MARKET_STATUS.OPEN
          : MARKET_STATUS.CLOSED

        setWalletState(prevState => ({ ...prevState, marketOpen }))
        resolve(marketOpen)
      } catch (error) {
        console.log(error)
        reject(WalletErrors.ERROR_FETCHING_MARKET)
      }
    })
  }

  const loadUser = () => {
    requireWeb3()
      .then(getNetwork)
      .then(connectMetaMask)
      .then(updateWalletInfo)
      .then(fetchMarketStatus)
      .catch(e => {
        setWalletState(prevState => ({
          ...prevState,
          errorMessage: e,
          marketOpen: MARKET_STATUS.ERROR,
          readyToPurchase: false,
          loading: false,
        }))
      })
  }

  useEffect(() => {
    const loadFn = () => {
      setWalletState(prevState => ({ ...prevState, web3Loaded: true }))
    }
    if ("web3" in window && "ethereum" in window) {
      loadFn()
    } else {
      const script = document.getElementById("web3")!
      script.addEventListener("load", loadFn)
    }
  }, [])

  useEffect(() => {
    if (walletState.web3Loaded && window.ethereum) {
      if (window.Web3 && window.Web3.givenProvider) {
        window.web3 = new window.Web3(window.Web3.givenProvider)
        loadUser()

        window.ethereum.on("accountsChanged", () => {
          console.log("ACCOUNT CHANGED")
          setWalletState(prevState => ({ ...prevState, errorMessage: null }))
          loadUser()
        })

        window.ethereum.on("chainChanged", () => {
          console.log("CHAIN CHANGED")
          setWalletState(prevState => ({ ...prevState, errorMessage: null }))
          loadUser()
        })
      } else {
        setWalletState(prevState => ({
          ...prevState,
          errorMessage: WalletErrors.NO_WEB3,
        }))
      }
    }
  }, [walletState.web3Loaded])

  const resetSpinner = () => {
    setWalletState(prevState => ({ ...prevState, adoptState: null }))
  }

  const purchaseNFT = async () => {
    try {
      setWalletState(prevstate => ({
        ...prevstate,
        errorMessage: null,
        adoptState: ADOPT_STATE.ADOPTING,
      }))
      const ingContract = new window.web3.eth.Contract(
        INGREDIENTS_ABI,
        INGREDIENTS_ADDRESS
      )
      const price = await ingContract.methods.getPrice().call()
      const totalPrice = price * mintCount

      const transactionParameters = {
        to: INGREDIENTS_ADDRESS,
        from: window.ethereum.selectedAddress,
        value: totalPrice.toString(),
      }

      console.log(price, mintCount, transactionParameters)

      await ingContract.methods
        .dumpsterDive(mintCount)
        .send(transactionParameters)
      setWalletState(prevstate => ({
        ...prevstate,
        errorMessage: null,
        adoptState: ADOPT_STATE.ADOPTED,
      }))
    } catch (e: any) {
      console.log(e)
      if (e.code && e.code == 4001) {
        setWalletState(prevState => ({ ...prevState, adoptState: null }))
      } else {
        setWalletState(prevState => ({
          ...prevState,
          errorMessage: WalletErrors.ADOPT_ERROR,
          adoptState: null,
        }))
      }
    }
  }

  useEffect(() => {
    play()
  }, [])

  return (
    <>
      <div className={bg}>
        <video
          ref={videoRef}
          className={video}
          loop
          muted={true}
          playsInline={true}
        >
          <source src={vid} type="video/mp4" />
        </video>{" "}
      </div>
      <div className={frycooks}>
        <h2 className={fryCookHeader}>Dumpster Diving</h2>
        <h6
          className={`${ingMarketStatus} ${
            walletState.marketOpen === MARKET_STATUS.CLOSED ||
            walletState.marketOpen === MARKET_STATUS.ERROR
              ? closedRed
              : ""
          }`}
        >
          Ingredients Market Status:
        </h6>
        <h6
          className={`${ingMarketStatus} ${statusSpace} ${
            walletState.marketOpen === MARKET_STATUS.CLOSED ||
            walletState.marketOpen === MARKET_STATUS.ERROR
              ? closedRed
              : ""
          }`}
        >
          {walletState.marketOpen}
        </h6>
        <p className={fryCookText}>
          ALL 2,018 DUMPSTER INGREDIENTS WILL BE MINTED AT 0.025ETH WITH A
          TRANSACTION MAXIMUM OF 20 AT A TIME
        </p>
        <div
          className={`${incrementBtn} ${
            !walletState.readyToPurchase ? btnDisabled : ""
          }`}
        >
          <button
            disabled={!walletState.readyToPurchase || !!walletState.adoptState}
            onClick={decrementMintCount}
            className={arrowContainer}
          >
            <ArrowLeft className={arrow} />
          </button>
          <p className={count}>{mintCount}</p>
          <button
            disabled={!walletState.readyToPurchase || !!walletState.adoptState}
            onClick={incrementMintCount}
            className={arrowContainer}
          >
            <ArrowRight className={arrow} />
          </button>
        </div>
        <button
          disabled={
            !walletState.readyToPurchase ||
            !!walletState.adoptState ||
            walletState.marketOpen === MARKET_STATUS.CLOSED
          }
          onClick={purchaseNFT}
          className={`${incrementBtn} ${mintBtn} ${
            walletState.marketOpen === MARKET_STATUS.CLOSED ? mintClosed : ""
          } ${!walletState.readyToPurchase ? btnDisabled : ""}`}
        >
          <p className={`${mint} ${!!walletState.adoptState ? mintAdopt : ""}`}>
            Dive!
          </p>
          <Spinner
            resetSpinner={resetSpinner}
            adoptState={walletState.adoptState}
          />
        </button>
        {walletState.errorMessage && (
          <>
            <p className={errorText}>{walletState.errorMessage}</p>
            <p className={errorText}>
              Verfify your wallet settings and click{" "}
              <span>
                <button onClick={loadUser} className={errorBtn}>
                  here
                </button>
              </span>{" "}
              to reconnect
            </p>
          </>
        )}

        <div className={remaining}>
          {walletState.totalSupply && (
            <ProgressBar
              remaining={walletState.totalSupply}
              totalSupply={totalInCollection}
              progress={walletState.totalSupply / totalInCollection}
            />
          )}
        </div>
      </div>
      <div className={faqWrapper}>
        <div className={faqContainer}>
          <div className={faqSection}>
            <h4 className={faqHeader}>What can I mint from the dumpster?</h4>
            <p className={faqText}>
              Trash of all kinds, plus the scattered occurance of some covetted
              EMPLOYED INGREDIENTS.
            </p>
          </div>

          <div className={faqSection}>
            <h4 className={faqHeader}>
              Is there another way to get dumpster ingresients?
            </h4>
            <p className={faqText}>
              Yes! If you have an UNEMPLOYED FRYCOOK you can{" "}
              <Link className={faqLink} to="/cookredeem">
                redeem it
              </Link>{" "}
              to recieve your complimentary DUMPSTER INGREDIENTS! There’s
              nothing better in this world than free trash!
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default FryCooks
